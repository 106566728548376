import React, { useState, useEffect } from 'react';
import html2pdf from 'html2pdf.js';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const HTMLToPDFDownloader = ({ htmlString, filename = 'certificate.pdf' }) => {
    const [loader, setLoader] = useState(false);
    const { t } = useTranslation()
    const downloadPDF = async () => {
        setLoader(true)
        // Create a temporary div to hold the HTML string
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;

        // Find all images in the HTML
        const images = tempDiv.getElementsByTagName('img');

        // Preload images to ensure they're rendered in PDF
        const imagePromises = Array.from(images).map(img => {
            return new Promise((resolve, reject) => {
                const tempImg = new Image();
                tempImg.crossOrigin = 'Anonymous'; // Set cross-origin attribute
                tempImg.onload = () => {
                    // Replace src with base64 to avoid cross-origin issues
                    img.src = getBase64Image(tempImg);
                    resolve();
                };
                tempImg.onerror = reject;
                tempImg.src = img.src;
            });
        });
        // const opt = {
        //     margin: [10, 10, 10, 10], // top, right, bottom, left margins in mm
        //     filename: filename,
        //     image: { type: 'jpeg', quality: 0.98 },
        //     html2canvas: {
        //       scale: 2, // Increases the resolution
        //       useCORS: true, // Allows cross-origin images to be captured
        //       logging: false // Disable logging to reduce noise
        //     },
        //     jsPDF: {
        //       unit: 'mm',
        //       format: 'a4',
        //       orientation: 'landscape'
        //     }
        //   };
        const opt = {
            margin: [10, 10, 10, 10], // top, right, bottom, left margins in mm
            filename: filename,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                scale: 3, // Increased scale for better quality
                useCORS: true,
                logging: false,
                windowWidth: 1200, // Explicitly set width
                windowHeight: 800  // Explicitly set height
            },
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'landscape'
            },
            pagebreak: { mode: 'avoid-all' } // Try to keep content on one page
        };
        try {
            // Wait for all images to load
            await Promise.all(imagePromises);

            // Configuration for html2pdf


            // Convert the HTML to PDF
            html2pdf().set(opt).from(tempDiv).save();
            setLoader(false)
        } catch (error) {
            console.error('Error loading images:', error);
            // Fallback to PDF generation without image
            html2pdf().set(opt).from(tempDiv).save();
            setLoader(false)
        }
    };

    // Utility function to convert image to base64
    const getBase64Image = (img) => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        return canvas.toDataURL('image/jpeg');
    };

    return (
        <>
            {<Button
                onClick={downloadPDF}
                className="downlod-icon"
            >
                {loader ? <i class="fa-solid fa-spinner"></i> : t("download")}
            </Button>}
        </>
    );
};

export default HTMLToPDFDownloader;