import { Card, Col, Container, Row } from 'react-bootstrap';
import defaultBadge from '../../assests/images/silver-medal.png'
import { formatDateWithoutTime } from '../../utils/formateDate';
import HTMLToPDFDownloader from './PdfDownlod';

export default function Certificates({ data, t }) {
    console.log("html 0000", data);
    return (
        // <Container>
        <Row>
            {data?.length > 0 ? data?.map((e) => {
                return (<Col md={6} xs={6} >
                    {/* <div class="certificate-container">
                        <div class="certificate-title">Certificate of Achievement</div>
                        <div class="certificate-body">
                            This is to certify that
                        </div>
                        <div class="recipient-name">{e?.fullName}</div>
                        <div class="certificate-body">
                            has successfully completed the course <strong><b>'{e?.category}'</b></strong><br />
                        </div>
                        <div className="logo-container" style={{ textAlign: 'center', margin: '30px 0' }}>
                            <img
                                src={e?.companyDetails?.logo}
                                alt="Logo"
                                style={{ height: 'auto' }}
                            />
                        </div>
                        <div class="signature">  
                            <div>
                                <div>{formatDateWithoutTime(e.date)}</div>
                                <div class="signature-line"></div>
                            </div>
                            <div>
                                <div>{e?.companyDetails?.name}</div>
                                <div class="signature-line"></div>
                            </div>
                        </div>
                    </div> */}
                    <div>
                        <HTMLToPDFDownloader htmlString={e?.certificateHTML} />
                        <div
                            dangerouslySetInnerHTML={{ __html: e?.certificateHTML }}
                        />
                    </div>
                </Col>)
            })
                :
                <div className="no-data-div">
                    <div>
                        <div className="nodata-table-view">
                            <div className="nodata-table-view-box">
                                <div className="nodata-table-view-box-img">
                                    <img src="/images/no-data-image1.jpg" alt="" />
                                </div>
                                <div className="nodata-table-view-box-txt">
                                    {t("sorry_no_data_found")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Row>
        // </Container>
    )
}
