import { Icon } from '@iconify/react'
import React from 'react'
import { ProgressBar } from 'react-bootstrap'

export default function CourseProgressDetails({ progress, t }) {
    return (
        <>
            <div className='row' style={{ width: "100%" }}>
                {/* <div>
                    <button className='see-all-button'>show all <i class="fa-solid fa-eye"></i></button>
                </div> */}
                {progress?.length > 0 ?
                    progress?.map((e) => {
                        return (
                            <>
                                <div className='col-md-12 progress-tile'>

                                    {/* <img className='progress-tile-img' src={`/images/folder(0).png`} /> */}
                                    <Icon icon="fxemoji:folder" width="3rem" height="3rem" className='progress-tile-img'/>
                                    <div className='progress-tile-title'>
                                        {e?.name}
                                        <ProgressBar
                                            variant="success"
                                            now={e?.percentage}
                                            style={{ height: '10px', marginTop: "3px", borderRadius: "10px" }} // Adjust the height here
                                        />
                                    </div>

                                </div>
                                {/*  */}

                            </>
                        )
                    }) :
                    <div>
                        <div className="nodata-table-view">
                            <div className="nodata-table-view-box">
                                <div className="nodata-table-view-box-img">
                                    <img src="/images/no-data-image1.jpg" alt="" />
                                </div>
                                <div className="nodata-table-view-box-txt">
                                    {t("sorry_no_data_found")}
                                </div>
                            </div>
                        </div>
                    </div>

                }
                {/* <div className='col-md-12 progress-tile'>

                    <img className='progress-tile-img' src={`/images/folder(0).png`} />
                    <div className='progress-tile-title'>
                        Designing Basics
                        <ProgressBar
                            variant="success"
                            now={45}
                            style={{ height: '10px', marginTop: "3px", borderRadius: "10px" }} // Adjust the height here
                        />
                    </div>

                </div> */}
                {/* <div className='col-md-12 progress-tile'>
                    <img className='progress-tile-img' src={`/images/folder(0).png`} />

                    <div className='progress-tile-title'>
                        MERN Stack
                        <ProgressBar
                            variant="success"
                            now={10}
                            style={{ height: '10px', marginTop: "3px", borderRadius: "10px" }} // Adjust the height here
                        />
                    </div>
                </div>
                <div className='col-md-12 progress-tile'>
                    <img className='progress-tile-img' src={`/images/folder(0).png`} />

                    <div className='progress-tile-title'>
                        MLM Marketing
                        <ProgressBar
                            variant="success"
                            now={95}
                            style={{ height: '10px', marginTop: "3px", borderRadius: "10px" }} // Adjust the height here
                        />
                    </div>
                </div>
                <div className='col-md-12 progress-tile'>
                    <img className='progress-tile-img' src={`/images/folder(0).png`} />

                    <div className='progress-tile-title'>
                        MLM Basics
                        <ProgressBar
                            variant="success"
                            now={36}
                            style={{ height: '10px', marginTop: "3px", borderRadius: "10px" }} // Adjust the height here
                        />
                    </div>
                </div> */}
            </div>
        </>
    )
}
