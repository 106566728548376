import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { privateRoutes, publicRoutes, webRoutes ,lmsRoutes } from "./Routes/router";

import MainLayout from "./Layouts/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsAuthenticated,
  setLoginResponse,
} from "./store/reducers/userReducer";
import { useEffect } from "react";
import { setLoginResponseCheck } from "./utils/checkStorage";
import PageTitle from "./components/Common/PageTitle";

function App() {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.user?.isAuthenticated);
  const userData = useSelector(
    (state) => state.user?.loginResponse?.user
  );
  const token = !!localStorage.getItem("access-token");
  const isAuthenticated = isLogin || token;
  const userType = userData ? JSON.parse(userData)?.userType : null;
  console.log(typeof userType);

  useEffect(() => {
    if (token) {
      const data = setLoginResponseCheck();
      dispatch(setLoginResponse(data));
      dispatch(setIsAuthenticated(true));
    }
  }, [dispatch, token]);

  const renderPrivateRoutes = () => {
    return privateRoutes.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ));
  };

  const renderWebRoutes = () => {
    return webRoutes.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ));
  };

  const renderPublicRoutes = () => {
    return publicRoutes.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ));
  };

  const renderLMSRoutes = () => {
    return lmsRoutes.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ));
  };

  return (
    <BrowserRouter>
      <PageTitle />
      <Routes>
        {isAuthenticated && userType === "mlm" ? (
          <>
          {console.log("-------------------------------")}
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route
              path="/login"
              element={<Navigate to="/dashboard" replace />}
            />
            <Route path="/" element={<MainLayout />}>
              {renderPrivateRoutes()}
            </Route>
            {renderWebRoutes()}
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </>
        ) : isAuthenticated && userType === "normal" ? (
          <>
            <>
              <Route path="/" element={<Navigate to="/acadamy-dashboard" replace />} />
              <Route
                path="/login"
                element={<Navigate to="/acadamy-dashboard" replace />}
              />
              <Route path="/" element={<MainLayout />}>
                {renderLMSRoutes()}
              </Route>
              
              <Route path="*" element={<Navigate to="/acadamy-dashboard" replace />} />
            </>
          </>
        ) : (
          <>
            {renderPublicRoutes()}
            <Route path="*" element={<Navigate to="/login" replace />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
