import React, { Component, createRef } from 'react';

class VideoControls extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.isPlaying !== nextProps.isPlaying;
  }

  render() {
    const { isPlaying, onPlay, onPause, onRestart, onJumpToTime } = this.props;

    return (
      <div className="video-controls-overlay">
        <button
          onClick={isPlaying ? onPause : onPlay}
          className="control-button"
        >
          {isPlaying ? "Pause" : "Play"}
        </button>
        <button
          onClick={onRestart}
          className="control-button"
        >
          Restart
        </button>
        <button
          onClick={() => onJumpToTime(30)}
          className="control-button"
        >
          Jump to 30s
        </button>
      </div>
    );
  }
}

class VideoDetails extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.currentVideo?.title !== nextProps.currentVideo?.title ||
      this.props.currentVideo?.description !== nextProps.currentVideo?.description ||
      this.props.currentVideo?.userViewed?.testPassed !== nextProps.currentVideo?.userViewed?.testPassed ||
      this.props.currentVideo?.userViewed?.completed !== nextProps.currentVideo?.userViewed?.completed
    );
  }

  render() {
    const { currentVideo, onQuizClick, t } = this.props;

    return (
      <div className="video-details">
        <div className="video-details-row">
          <div className="video-title">
            <b>{currentVideo?.title}</b>
          </div>
          <div className="quiz-section">
            {currentVideo?.userViewed?.testPassed ? (
              <div className="test-passed">
                {`${t("test_passed")} `}
                <i className="check-icon"></i>
              </div>
            ) : (
              <button
                onClick={onQuizClick}
                className="quiz-button"
                disabled={currentVideo?.userViewed?.completed !== 1}
              >
                {t("quiz")}
              </button>
            )}
          </div>
        </div>
        <p className="video-description">{currentVideo?.description}</p>
      </div>
    );
  }
}

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false
    };
    this.videoRef = createRef();
  }

  componentDidMount() {
    this.setInitialVideoTime();
    this.addVideoEventListeners();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentVideo?.videoUrl !== this.props.currentVideo?.videoUrl) {
      this.setInitialVideoTime();
    }
  }

  componentWillUnmount() {
    this.removeVideoEventListeners();
  }

  setInitialVideoTime = () => {
    const { currentVideo } = this.props;
    const video = this.videoRef.current;
    
    if (video && currentVideo?.userViewed?.progress) {
      const startTime = Math.max(0, Number(currentVideo.userViewed.progress) - 1);
      video.currentTime = startTime;
    }
  }

  addVideoEventListeners = () => {
    const video = this.videoRef.current;
    if (video) {
      video.addEventListener("timeupdate", this.handleTimeUpdate);
      video.addEventListener("ended", this.handleVideoEnd);
    }
  }

  removeVideoEventListeners = () => {
    const video = this.videoRef.current;
    if (video) {
      video.removeEventListener("timeupdate", this.handleTimeUpdate);
      video.removeEventListener("ended", this.handleVideoEnd);
    }
  }

  handleTimeUpdate = () => {
    const video = this.videoRef.current;
    if (video) {
      this.props.setDuration(video.currentTime);
    }
  }

  handleVideoEnd = () => {
    const { currentVideo, onFinish } = this.props;
    if (currentVideo?.id && currentVideo?.userViewed?.id) {
      onFinish({
        videoId: currentVideo.id,
        historyId: currentVideo.userViewed.id,
      });
    }
  }

  handlePlay = () => {
    const video = this.videoRef.current;
    if (video) {
      video.play();
      this.setState({ isPlaying: true });
    }
  }

  handlePause = () => {
    const video = this.videoRef.current;
    const { currentVideo, videoProgressHandler } = this.props;
    
    if (video) {
      video.pause();
      this.setState({ isPlaying: false });
      videoProgressHandler({
        videoId: currentVideo?.id,
        categoryId: currentVideo?.userViewed?.categoryId,
        historyId: currentVideo?.userViewed?.id,
      });
    }
  }

  handleRestart = () => {
    const video = this.videoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play();
      this.setState({ isPlaying: true });
    }
  }

  handleJumpToTime = (seconds) => {
    const video = this.videoRef.current;
    if (video) {
      video.currentTime = seconds;
    }
  }

  handleQuiz = () => {
    const { setQuiz, setShow } = this.props;
    setQuiz(true);
    setShow(true);
  }

  render() {
    const { currentVideo, t } = this.props;
    const { isPlaying } = this.state;

    if (!currentVideo?.videoUrl) return null;

    return (
      <div className="video-player">
        <div className="video-container">
          <video
            ref={this.videoRef}
            className="video-element"
            // src={currentVideo.videoUrl}
            preload="metadata"
            controls={true}
          >
            <source src={currentVideo.videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          {/* <VideoControls
            isPlaying={isPlaying}
            onPlay={this.handlePlay}
            onPause={this.handlePause}
            onRestart={this.handleRestart}
            onJumpToTime={this.handleJumpToTime}
          /> */}
        </div>
       

        <VideoDetails 
          currentVideo={currentVideo}
          onQuizClick={this.handleQuiz}
          t={t}
        />
      </div>
    );
  }
}

export default VideoPlayer;