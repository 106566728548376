import { useState, useEffect } from "react";

const StripeLoading = ( { text }) => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length >= 3 ? "" : prev + "."));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <p style={{ fontSize: "18px", color: "#4a5568", fontWeight: "bold" }}>
      {text}{dots}
    </p>
  );
};

export default StripeLoading;
