import axios from "axios";
import API from "../../api/api";
import { BASE_URL, DEFAULT_KEY } from "../../config/config";

const callApi = async (endpoint, method = 'get', body = null) => {
    try {
        let response;

        switch (method.toLowerCase()) {
            case 'get':
                response = await API.get(endpoint);
                break;
            case 'post':
                response = await API.post(endpoint, body);
                break;
            case 'delete':
                response = await API.delete(endpoint, { data: JSON.stringify(body) });
                break;
            case 'patch':
                response = await API.patch(endpoint, body);
                break;
            default:
                throw new Error(`Unsupported HTTP method: ${method}`);
        }
        return response?.data;

    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const boardServices = {
    getBoardTiles:() => callApi(`get-board-tiles`,'get'),
    getBoards: (boardId, page, perPage) => callApi(`get-boards?boardId=${boardId}&page=${page}&perPage=${perPage}`, 'get'),
    viewBoard: (boardId, autoBoardId) => callApi(`view-board?boardId=${boardId}&autoBoardId=${autoBoardId}`)

}