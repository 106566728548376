import { t } from 'i18next'
import React from 'react'
import { Card } from 'react-bootstrap'
import defaultBadge from '../../assests/images/silver-medal.png'


export default function QuizComplete({ quizComplete, data }) {
    return (
        <>
            {data.questions.length === 0 ?
                <div className='quiz-answer-preview'>
                    <Card style={{ border: "none" }}>
                        <Card.Body className={`quiz-result-view ${quizComplete.testPassed ? 'quiz-result-view-pass' : "quiz-result-view-fail"}`}>
                            <div>{quizComplete.testPassed &&
                                <div>{t("qualified")} <i class="fa-solid fa-circle-check fa-lg" style={{ color: "#36da16" }}></i>

                                </div>}


                            </div>
                            {/* <div>{`Your score is ${quizComplete.totalQuestionsPassed} out of ${quizComplete.totalQuestions}`}</div> */}

                        </Card.Body>
                        {quizComplete.badgeGained &&
                            <>

                                <hr />
                                <div>
                                    Badges Gained
                                </div>
                                <div style={{ width: "15rem", textAlign: "center", position: "relative" }}>
                                    <img
                                        src={quizComplete.badgeGained?.image || defaultBadge}
                                        alt="badge-image"
                                        style={{ width: "10rem", padding: "10px" }}
                                    />
                                    <br />
                                    <span>{`${quizComplete.badgeGained?.name}`}</span>
                                </div>
                            </>
                        }
                        {quizComplete.certficateGained &&
                            <>
                                <hr />
                                <div>
                                    +1 Course Certificate <i class="fa-solid fa-circle-check fa-lg" style={{ color: "#36da16" }}></i>
                                </div>
                            </>
                        }
                    </Card>
                </div>
                :
                <div className='quiz-answer-preview'>
                    <Card style={{ border: "none" }}>
                        <Card.Body className={`quiz-result-view ${quizComplete.testPassed ? 'quiz-result-view-pass' : "quiz-result-view-fail"}`}>
                            <div>{quizComplete.testPassed ?
                                <div>{t("passed")} <i class="fa-solid fa-circle-check fa-lg" style={{ color: "#36da16" }}></i></div> :
                                <div>{t("failed")} <i class="fa-solid fa-circle-xmark fa-lg" style={{ color: "#e61919" }}></i></div>}
                            </div>
                            <div>{`Your score is ${quizComplete.totalQuestionsPassed} out of ${quizComplete.totalQuestions}`}</div>

                        </Card.Body>
                        {quizComplete.badgeGained &&
                            <>

                                <hr />
                               
                                <div style={{ width: "100%", textAlign: "center", position: "relative" }}>
                                <div>
                                    Badges Gained
                                </div>
                                    <img
                                        src={quizComplete.badgeGained?.image || defaultBadge}
                                        alt="badge-image"
                                        style={{ width: "10rem", padding: "10px" }}
                                    />
                                    <br />
                                    <span>{`${quizComplete.badgeGained?.name}`}</span>
                                </div>
                            </>
                        }
                        {quizComplete.certficateGained &&
                            <>
                                <div>
                                    +1 Course Certificate <i class="fa-solid fa-circle-check fa-lg" style={{ color: "#36da16" }}></i>
                                </div>
                            </>
                        }
                    </Card>
                </div>}
        </>
    )
}
