import { Card, Col, Container, Row } from 'react-bootstrap';
import defaultBadge from '../../assests/images/silver-medal.png'

export default function Badges({ badges, t }) {
  return (
    // <Container>
    <Row>
      {badges?.length > 0 ? badges?.map((e) => {
        return (<Col md={2} xs={2} >
          <div style={{ width: "15rem", textAlign: "center", position: "relative" }}>
            <img
              src={e?.image || defaultBadge}
              onError={(e) => e.target.src = defaultBadge}
              alt="badge-image"
              style={{ width: "10rem", padding: "10px" }}
            />
            <span className="badge-count" >
              {`${e?.count}`}
            </span>
            <br />
            <span>{`${e?.name}`}</span>
          </div>
        </Col>)
      })
        :
        <div className="no-data-div">
          <div>
            <div className="nodata-table-view">
              <div className="nodata-table-view-box">
                <div className="nodata-table-view-box-img">
                  <img src="/images/no-data-image1.jpg" alt="" />
                </div>
                <div className="nodata-table-view-box-txt">
                  {t("sorry_no_data_found")}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </Row>
    // </Container>
  )
}
