import React from 'react'

export default function CourseCard({ category, name, desciprion, image, setSelectedCategory }) {
    const thumbnail = Boolean(image) ? image : "/images/course-cat.png"
    return (
        <>
            <div class="card-container courseCard">
                <div class="card">
                    <div class="card-image">
                        <img src={thumbnail} alt="Course Thumbnail"
                            onError={(e) => {
                                e.target.src = "/images/course-cat.png"
                            }}
                        />
                    </div>
                    <div class="card-content">
                        <h3 class="card-title">{name}</h3>
                        <div className='card-desciption-content'>
                            <p class="card-description">
                                {desciprion}
                            </p>
                        </div>
                    </div>
                    <div class="card-buttons">
                        <button class="card-arrow" onClick={() => setSelectedCategory(category)}>→</button>
                    </div>
                </div>
            </div>
        </>
    )
}
