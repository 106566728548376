import { lmsServcies } from "../../services/lms/lms";

export const getVideoList = async ({category}) => {
    try {
        const response = await lmsServcies.getVideoList({category});
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};
export const getSingleVideo = async ({currentVideoId,category}) => {
    try {
        const response = await lmsServcies.getSingleVideo({currentVideoId,category});
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};
export const updateVideoProgress = async (data) => {
    try {
        const response = await lmsServcies.updateVideoProgress(data);
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};
export const updateVideoComplete = async (data) => {
    try {
        const response = await lmsServcies.updateVideoComplete(data);
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};
export const getTestQuestions = async (id) => {
    try {
        const response = await lmsServcies.getTestQuestions(id);
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};
export const validateTest = async (data) => {
    try {
        const response = await lmsServcies.validateTest(data);
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};
export const getVideoCategories = async () => {
    try {
        const response = await lmsServcies.getVideoCategories();
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};
export const getUserBadges = async () => {
    try {
        const response = await lmsServcies.getUserBadges();
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};
export const getUserCertificates = async () => {
    try {
        const response = await lmsServcies.getUserCertificates();
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};

export const getLmsTestHistories = async (page,perPage) => {
    try {
        const response = await lmsServcies.getLmsTestHistories(page,perPage);
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};
export const getLmsDashboard = async () => {
    try {
        const response = await lmsServcies.getLmsDashboard();
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};

