import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

export default function TaskDetailsChart({ taskData, t }) {
    const chartRef = useRef(null); // Reference for the canvas

    useEffect(() => {
        // Create the chart when the component mounts
        const ctx = chartRef.current.getContext('2d');
        const data = {
            labels: [t('tasks_completed'), t('tests_passed'), t('total_tasks')],
            datasets: [{
                // label: 'Quantity',
                data: [taskData?.completed, taskData?.testPassed, taskData?.totalTasks],
                backgroundColor: [
                    'rgb(75, 192, 192)',    // A teal color for completed tasks
                    'rgb(54, 162, 235)',    // Blue for quality checks
                    'rgb(153, 102, 255)'    // Purple for total scope
                ],
                hoverOffset: 4,
                borderWidth: 1,
                borderColor: '#fff',
                responsive: true
            }]
        };
        const config = {
            type: 'pie',
            data: data,
            options: {
                responsive: true, // Make the chart responsive
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    },
                }
            },
        };

        const myChart = new Chart(ctx, config);

        // Cleanup function to destroy the chart on unmount
        return () => {
            myChart.destroy();
        };
    }, [t]);

    return (
        <div className='d-flex justify-content-center w-100'>
            <div style={{ width: "100%", padding: "25px" }}>
                <canvas ref={chartRef}></canvas>
            </div>
        </div>

    );
}
