import React, { useEffect, useState } from "react";
import VimeoPlayer from "../../components/Vimeo/VimeoPlayer";
import Quiz from "../../components/Vimeo/Quiz";
import VideoList from "../../components/Vimeo/VideoList";
import { ApiHook } from "../../hooks/apiHook";
import { Button, ProgressBar } from "react-bootstrap";
import { t } from "i18next";
import { toast } from "react-toastify";
import Loader from "react-js-loader";

export default function MainVimeo({
  tab,
  category,
  categoryName,
  backToCategories,
}) {
  const [quiz, setQuiz] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState("latest");
  const [duration, setDuration] = useState(0);
  const [show, setShow] = useState(false);
  const [key, setKey] = useState(0);

  const videoList = ApiHook.CallVimeoVideos({ category });
  const currentVideo = ApiHook.CallSingleVideo({
    currentVideoId,
    category,
    key,
    setKey,
  });
  const updateProgress = ApiHook.CallVideoProgress();
  const videoFinish = ApiHook.CallVideoComplete();

  const setCurrentVideoHandler = (data) => {
    setCurrentVideoId(data?.id);
  };
  const videoFinishHandler = async (data) => {
    data["progress"] = duration;
    const response = await videoFinish.mutateAsync(data);
    if (response?.data?.status) {
      setCurrentVideoId(data.videoId);
      currentVideo.refetch();
      videoList.refetch();
    }
  };
  const refetchVideoList = async () => {
    setCurrentVideoId("latest");
    currentVideo.refetch();
    await videoList.refetch();
  };
  const videoProgressHandler = async (data, type) => {
    data["progress"] = duration;
    const response = await updateProgress.mutateAsync(data);
    if (response?.data?.status && type === "save")
      toast.success(t("progress_saved"));
  };
  const videoResetHandler = async (data) => {
    data["progress"] = 0;
    const response = await updateProgress.mutateAsync(data);
    if (response?.data?.status) toast.success(t("progress_reset"));
    currentVideo.refetch({ currentVideoId: data.videoId, category });
    videoList.refetch();
  };
  useEffect(() => {
    if (tab === "home") {
      refetchVideoList();
    }
  }, [tab]);
  //https://www.dropbox.com/scl/fi/ty0jvvwfr5t541zjmcr7c/Screencast-from-06-01-25-06-21-55-PM-IST.mp4?rlkey=pezys2p5eyhauo9aqibmxtgt6&st=4q2382qz&raw=1
  return (
    <>
      <div className="row">
        <div className="col-md-4 vimeo-header">
          <Button style={{ borderRadius: "50%" }} onClick={backToCategories}>
            <i class="fa-solid fa-chevron-left fa-lg"></i>
          </Button>
          <div className="category-header">
            {`${categoryName ? categoryName.toUpperCase() : t("invalid")}`}
          </div>
        </div>
        <div style={{ padding: "10px" }}>
          <ProgressBar
            variant="success"
            now={videoList?.data?.data?.data?.completedPerc ?? 0}
            label={`${videoList?.data?.data?.data?.completedPerc ?? 0}%`}
            style={{ height: "30px", borderRadius: "10px" }} // Adjust the height here
          />
        </div>
      </div>
      <br />
      {videoList.isLoading ? (
        <div className="vimeo-loader">
          <Loader
            type="bubble-top"
            bgColor={"grey"}
            color={"grey"}
            size={100}
          />
        </div>
      ) : (
        <>
          {videoList?.data?.data?.data?.videoList?.length > 0 &&
          videoList.isFetched &&
          !videoList.isLoading ? (
            <div className="row">
              <div className="col-md-7">
                {quiz ? (
                  <Quiz
                    show={show}
                    setShow={setShow}
                    setQuiz={setQuiz}
                    currentVideo={currentVideo?.data?.data?.data}
                    refetchVideoList={refetchVideoList}
                  />
                ) : (
                  <>
                    {currentVideo.isLoading ? (
                      <>
                        <div className="vimeo-loader">
                          <Loader
                            type="spinner-circle"
                            bgColor={"grey"}
                            color={"grey"}
                            title={"loading"}
                            size={100}
                          />
                        </div>
                      </>
                    ) : (
                      currentVideo?.data?.data?.data?.videoUrl && (
                        <VimeoPlayer
                          currentVideo={currentVideo?.data?.data?.data}
                          setQuiz={setQuiz}
                          onFinish={videoFinishHandler}
                          setDuration={setDuration}
                          setShow={setShow}
                          videoProgressHandler={videoProgressHandler}
                          keyVal={key}
                          t={t}
                        />
                      )
                    )}
                  </>
                )}
              </div>
              <div className="col-md-5">
                {videoList.isLoading ? (
                  <>
                    <div className="vimeo-loader">
                      <Loader
                        type="spinner-circle"
                        bgColor={"grey"}
                        color={"grey"}
                        title={"loading"}
                        size={100}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {videoList?.data?.data?.data?.videoList?.length > 0 && (
                      <VideoList
                        setCurrentVideo={setCurrentVideoHandler}
                        currentVideo={currentVideo?.data?.data?.data}
                        videoList={videoList?.data?.data?.data?.videoList}
                        onSave={videoProgressHandler}
                        onReset={videoResetHandler}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="nodata-table-view">
                <div className="nodata-table-view-box">
                  <div className="nodata-table-view-box-img">
                    <img src="/images/no-data-image1.jpg" alt="" />
                  </div>
                  <div className="nodata-table-view-box-txt">
                    {t("sorry_no_data_found")}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
