import { Icon } from "@iconify/react";
import React from "react";
import { useTranslation } from "react-i18next";

const LmsTiles = ({ tiles }) => {
  const { t } = useTranslation();
  return (
    <div className="ewallet_top_cnt_sction">
      <div className="row">
        <div className="col-xl-6 col-md-12">
          <div className="ewallet_top_qc_balance_box">
            <div className="row align-items-center">
              <div className="ewallet_top_qc_balance_box_cnt">
                <div className="ewallet_top_qc_balance_box_cnt_ico">
                  {/* <img src={"/images/crs-ico.png"} alt="" /> */}
                  <Icon icon="fa-solid:box-open" width="2.5rem" height="2.5rem"  style={{"color": "#3465a4"}}></Icon>
                </div>
                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                  <div className="ewallet_top_qc_balance_box_cnt_head">
                    {t("active_courses")}
                  </div>
                  <div className="box_amnt_dv">
                    <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                      {tiles?.activeCourse ?? 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-md-12">
          <div className="ewallet_top_qc_balance_box">
            <div className="row align-items-center">
              <div className="ewallet_top_qc_balance_box_cnt">
                <div className="ewallet_top_qc_balance_box_cnt_ico">
                  {/* <img src={"/images/badge-ico.png"} alt="" /> */}
                  <Icon icon="streamline:star-badge-solid" width="2.5rem" height="2.5rem"  style={{"color": "#3465a4"}}/>
                </div>
                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                  <div className="ewallet_top_qc_balance_box_cnt_head">
                    {t("total_badges")}
                  </div>
                  <div className="box_amnt_dv">
                    <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                      {tiles?.badge ?? 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-xl-4 col-md-12">
          <div className="ewallet_top_qc_balance_box">
            <div className="row align-items-center">
              <div className="ewallet_top_qc_balance_box_cnt">
                <div className="ewallet_top_qc_balance_box_cnt_ico"> */}
                  {/* <img src={"/images/cert-ico.png"} alt="" /> */}
                  {/* <Icon icon="fluent:certificate-24-filled" width="2.5rem" height="2.5rem" style={{ "color": "#3465a4" }} />
                </div>
                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                  <div className="ewallet_top_qc_balance_box_cnt_head">
                    {t("total_certificates")}
                  </div>
                  <div className="box_amnt_dv">
                    <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                      {tiles?.certificate ?? 0}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      </div>
    </div>
  );
};

export default LmsTiles;
