import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import DatePickerComponent from "../DatePickerComponent";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../../hooks/apiHook";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

const NextFollowUp = ({
    leadId,
    showNextFollowUp,
    setShowNextFollowUp,
    editFormData,
    setEditFormData,
}) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [currentFollowDate, setCurrentFollowDate] = useState(
        editFormData?.followupDate
    );
    const nextFollowDate = null;
    const [formData, setFormData] = useState({
        currentFollowDate: "",
        nextFollowDate: "",
    });
    const [errorMessage, setErrorMessage] = useState({
        currentFollowDate: "",
        nextFollowDate: "",
    });

    const handleClose = () => {
        setShowNextFollowUp(false);
        // Clear form fields when modal is closed
        setEditFormData({
            id: "",
            firstName: "",
            lastName: "",
            emailId: "",
            skypeId: "",
            mobileNo: "",
            countryId: "",
            description: "",
            interestStatus: "",
            followupDate: null,
            leadStatus: "",
        });
    };
    const addNextFollowUpMutation = ApiHook.CallAddNextFollowUp();

    const handleSubmit = (event) => {
        event.preventDefault();
        const today = dayjs(); // Get today's date

        // Check if follow-up date is in the past or today
        if (dayjs(formData.nextFollowDate).isBefore(today, 'day') || dayjs(formData.nextFollowDate).isSame(today, 'day')) {
            // Display error message
            toast.error(t("Next follow-up date cannot be today or in the past"));
            return; // Stop further execution
        }
        // Add the 'Id' property to the formData
        const data = {
            id: leadId,
            nextFollowupDate: formData.nextFollowDate,
        };
        addNextFollowUpMutation.mutateAsync(data, {
            onSuccess: (res) => {
                if (res?.status) {
                    toast.success(t(res?.data));
                    queryClient.invalidateQueries({ queryKey: ["crm-tiles"] });
                    queryClient.invalidateQueries({
                        queryKey: ["followup-today"],
                    });
                    queryClient.invalidateQueries({
                        queryKey: ["recent-leads"],
                    });
                    queryClient.invalidateQueries({
                        queryKey: ["missed-followup"],
                    });
                    queryClient.invalidateQueries({
                        queryKey: ["view-leads"],
                    });
                }
            },
        });

        handleClose();
    };

    const handleCurrentFollowUp = (newDate) => {
        if (newDate) {
            const formattedDate = newDate.format("YYYY-MM-DD");
            setCurrentFollowDate(newDate);
            setFormData((prev) => ({
                ...prev,
                currentFollowDate: formattedDate,
            }));
            setErrorMessage((prev) => ({
                ...prev,
                currentFollowDate: null,
            }));
        }
    };
    const handleNextFollowUp = (newDate) => {
        if (newDate) {
            //to be handle is before selection
            const formattedDate = newDate.format("YYYY-MM-DD");
            setFormData((prev) => ({
                ...prev,
                nextFollowDate: formattedDate,
            }));
            setErrorMessage((prev) => ({
                ...prev,
                nextFollowDate: null,
            }));
        }
    };
    useEffect(() => {
        setCurrentFollowDate(dayjs(editFormData?.followupDate));
    }, [editFormData]);

    return (
        <Modal
            id="nextFollowUp"
            show={showNextFollowUp}
            onHide={handleClose}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{t("next_followup_date")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="form-group">
                        <Form.Label>{t("Current_Follow_Date")}</Form.Label>
                        <DatePickerComponent
                            className={"date-picker"}
                            date={currentFollowDate}
                            disabled={true}
                            past={true}
                            handleChange={handleCurrentFollowUp}
                        />
                        {errorMessage.currentFollowDate && (
                            <span className="validation-error-message">
                                {errorMessage.currentFollowDate}
                            </span>
                        )}
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>{t("next_followup_date")}</Form.Label>
                        <DatePickerComponent
                            className={"date-picker"}
                            date={nextFollowDate}
                            past={true}
                            handleChange={handleNextFollowUp}
                        />
                        {errorMessage.nextFollowDate && (
                            <span className="validation-error-message">
                                {errorMessage.nextFollowDate}
                            </span>
                        )}
                    </Form.Group>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            {t("close")}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t("add_follow-up")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default NextFollowUp;
