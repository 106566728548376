import React from "react";
import LmsTiles from "../../components/Vimeo/LmsTiles";
import TaskDetailsChart from "../../components/Lms/TaskDetails";
import CourseProgressDetails from "../../components/Lms/CourseProgressDetails";
import AllCourse from "../../components/Lms/AllCourse";
import LastWatched from "../../components/Lms/LastWatched";
import { ApiHook } from "../../hooks/apiHook";
import CourseChart from "../../components/Lms/CourseChart";
import { useTranslation } from "react-i18next";
import CommonVimeo from "../../components/Vimeo/CommonVimeo";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Alert,
  CircularProgress,
} from "@mui/material";

export default function LmsDashboard() {
  const { t } = useTranslation();
  const dashboard = ApiHook.CallLmsDashboard();
  console.log("dashboard ----- ", dashboard?.data?.data?.data);

  return (
    <>
      <div className="page_head_top">{t("lms")}</div>
      <div className="ewallet_top_btn_sec">
        <div className="row justify-content-between">
          <LmsTiles tiles={dashboard?.data?.data?.data?.tiles} />
          <div
            className="joining_Teammbr_section"
            style={{ textAlign: "center" }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="joinings_viewBox lms-dashboard-wall last_watch_box">
                  <div className="joinings_viewBox_head lms-dashboard-wall-title">
                    {t("last_watched")}
                  </div>
                  <div className="joinings_viewBox_head teamperfomance">
                    {dashboard?.data?.data?.data?.lastWatched && (
                      <LastWatched
                        data={dashboard?.data?.data?.data?.lastWatched}
                        t={t}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-8 break_xl">
                <div className="joinings_viewBox lms-dashboard-wall lms_mn_box">
                  {/* <div className="joinings_viewBox_head">
                    {t("introduction")}
                  </div>
                  {dashboard?.data?.data?.data?.introductionVideo?.url && (
                    <div className="joinings_viewBox_head teamperfomance learning_progress_scrl">
                      <CommonVimeo
                        url={
                          dashboard?.data?.data?.data?.introductionVideo?.url
                        }
                      />
                    </div>
                  )} */}

                  <Grid item xs={12} md={4}>
                    <Card>
                      <CardContent>
                        <div
                          className="joinings_viewBox_head"
                          style={{ textAlign: "center" }}
                        >
                          {t("introduction")}
                        </div>
                        {dashboard?.data?.data?.data?.introductionVideo?.url ? (
                          <CommonVimeo
                            url={dashboard.data.data.data.introductionVideo.url}
                          />
                        ) : (
                          <Alert
                            severity="info"
                            sx={{
                              "& .MuiAlert-message": {
                                width: "100%",
                              },
                            }}
                          >
                            <div style={{ textAlign: "center", width: "100%" }}>
                              <Typography variant="h6" sx={{ mb: 1 }}>
                                Course Overview
                              </Typography>
                              <Typography>
                                Introduction video is not available
                              </Typography>
                            </div>
                          </Alert>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </div>
              </div>
              <div className="col-md-4">
                <div className="joinings_viewBox lms-dashboard-wall lms_mn_box">
                  <div className="joinings_viewBox_head">
                    {t("all_courses")}
                  </div>
                  <div className="joinings_viewBox_head teamperfomance learning_progress_scrl">
                    {dashboard?.data?.data?.data?.courseRankData && (
                      <AllCourse
                        data={dashboard?.data?.data?.data?.courseRankData}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="joinings_viewBox">
                  <div className="joinings_viewBox_head">
                    {t("task_analytics")}
                  </div>
                  <div className="row align-items-center">
                    <div className="col-md-7 break_xl">
                      <div className="joinings_viewBox_head teamperfomance">
                        {dashboard?.data?.data?.data?.taskAnalytics && (
                          <TaskDetailsChart
                            taskData={
                              dashboard?.data?.data?.data?.taskAnalytics
                            }
                            t={t}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 break_xl">
                      <div className="lmsDashboardRight_Cnt">
                        <span>
                          <div
                            style={{ backgroundColor: "#4bc0c0" }}
                            className="chartBxInf"
                          ></div>
                          <p>
                            {t("tasks_completed")} :
                            <strong>
                              {
                                dashboard?.data?.data?.data?.taskAnalytics
                                  ?.completed
                              }
                            </strong>
                          </p>
                        </span>
                        <span>
                          <div
                            style={{ backgroundColor: "#36a2eb" }}
                            className="chartBxInf"
                          ></div>
                          <p>
                            {t("tests_passed")} :
                            <strong>
                              {
                                dashboard?.data?.data?.data?.taskAnalytics
                                  ?.testPassed
                              }
                            </strong>
                          </p>
                        </span>
                        <span>
                          <div
                            style={{ backgroundColor: "#9966ff" }}
                            className="chartBxInf"
                          ></div>
                          <p>
                            {t("total_tasks")} :
                            <strong>
                              {
                                dashboard?.data?.data?.data?.taskAnalytics
                                  ?.totalTasks
                              }
                            </strong>
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="joinings_viewBox">
                  <div className="joinings_viewBox_head">
                    {t("course_progress")}
                  </div>

                  <div className="row align-items-center">
                    <div className="col-lg-12 col-xl-7 break_xl">
                      <div className="joinings_viewBox_head teamperfomance">
                        {dashboard?.data?.data?.data?.courseUnlocked && (
                          <CourseChart
                            courseData={
                              dashboard?.data?.data?.data?.courseUnlocked
                            }
                            t={t}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-4 break_xl">
                      <div className="lmsDashboardRight_Cnt">
                        <span>
                          <div
                            style={{ backgroundColor: "#2eb82e" }}
                            className="chartBxInf"
                          ></div>
                          <p>
                            {t("unlocked")} :
                            <strong>
                              {
                                dashboard?.data?.data?.data?.courseUnlocked
                                  ?.unlocked
                              }
                            </strong>
                          </p>
                        </span>
                        <span>
                          <div
                            style={{ backgroundColor: "#a6a6a6" }}
                            className="chartBxInf"
                          ></div>
                          <p>
                            {t("pending")} :
                            <strong>
                              {
                                dashboard?.data?.data?.data?.courseUnlocked
                                  ?.pending
                              }
                            </strong>
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="joinings_viewBox lms-dashboard-wall">
                  <div className="joinings_viewBox_head lms-dashboard-wall-title">
                    {t("learning_progress")}
                  </div>
                  <div className="joinings_viewBox_head teamperfomance learning_progress_scrl">
                    {dashboard?.data?.data?.data?.courseProgress && (
                      <CourseProgressDetails
                        progress={dashboard?.data?.data?.data?.courseProgress}
                        t={t}
                      />
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="col-md-4">
                                <div className="joinings_viewBox">
                                    <div className='joinings_viewBox_head'>
                                        {t("top_badges")}
                                    </div>
                                    <div className="joinings_viewBox_head teamperfomance">
                                        <TopBadge/>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
