import React from 'react'
import Vimeo from '@u-wave/react-vimeo'

export default function CommonVimeo({ url }) {
    return (
        <div style={{ width: "100%" }}>
            <Vimeo
                video={url}
                responsive
            // autoplay
            />
        </div>
    )
}
