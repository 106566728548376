import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import CourseCard from './CourseCard'


export default function CategoryList({ categories, setSelectedCategory, t }) {

    return (
        <>
            {categories?.length === 0 ?
                <div className="no-data-div">
                    <div>
                        <div className="nodata-table-view">
                            <div className="nodata-table-view-box">
                                <div className="nodata-table-view-box-img">
                                    <img src="/images/no-data-image1.jpg" alt="" />
                                </div>
                                <div className="nodata-table-view-box-txt">
                                    {t("sorry_no_data_found")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Row>
                    {categories?.map((category) => {
                        return <>
                            <Col md={3} style={{ margin: "10px", width: "auto" }}>
                                <CourseCard
                                    name={category.name}
                                    desciprion={category.description}
                                    image={`http://162.19.146.135:1675/storage/${category.image}`}
                                    setSelectedCategory={setSelectedCategory}
                                    category={category}
                                />
                            </Col>
                        </>
                    })}
                </Row>
            }
        </>
    )
}
