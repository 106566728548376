import React, { useState, useRef } from "react";
import { ApiHook } from "../../hooks/apiHook";
import MainVimeo from "./MainVimeo";
import CategoryList from "../../components/Vimeo/CategoryList";
import Badges from "../../components/Lms/Badges";
import Certificates from "../../components/Lms/Certificates";
import { Button } from "@mui/material"; // MUI Button
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Tabs, Tab, Box } from "@mui/material"; // MUI Tabs and Tab

export default function Categories() {
  const { t } = useTranslation();
  const [tab, setTab] = useState("home");
  const navigate = useNavigate();

  const toLmsDashboard = () => {
    navigate("/acadamy-dashboard");
  };

  const categoryList = ApiHook.CallVideoCategories(tab);
  const userBadges = ApiHook.CallUserBadges(tab);
  const userCertificates = ApiHook.CallUserCertificates(tab);
  const [category, setCategory] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const tabChangeHandler = (event, newTab) => {
    setTab(newTab);
    if (newTab === "home") categoryList.refetch();
  };

  const backToCategories = () => {
    setCategory(false);
    categoryList.refetch();
  };

  const setSelectedCategory = (data) => {
    setCategory(data.id);
    setCategoryName(data.name);
  };



  console.log(category," =========== category ============ ");
  
  return (
    <>
      <div className="page_head_top">{t("lms")}</div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={tabChangeHandler}
          aria-label="Categories Tabs"
          textColor="primary"
          indicatorColor="primary"
          variant="fullWidth"
        >
          <Tab value="home" label={t("home")} />
          <Tab value="badges" label={t("badges")} />
          {/* <Tab value="certificates" label={t("certificates")} /> */}
        </Tabs>
      </Box>
      <Box>
        <div className="row">
          <div className="col-md-12">
            <div style={{ float: "right", margin: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={toLmsDashboard}
              >
                {t(`dashboard`)}
              </Button>
            </div>
          </div>
        </div>
      </Box>
      <Box sx={{ p: 2 }}>
        {tab === "home" && (
          <>
            {!category ? (
              categoryList?.data?.data?.data && (
                <CategoryList
                  categories={categoryList?.data?.data?.data}
                  setSelectedCategory={setSelectedCategory}
                  t={t}
                />
              )
            ) : (
              <MainVimeo
                tab={tab}
                category={category}
                categoryName={categoryName}
                backToCategories={backToCategories}
              />
            )}
          </>
        )}
        {tab === "badges" && (
          <Badges badges={userBadges?.data?.data?.data?.badges} t={t} />
        )}
        {tab === "certificates" && (
          <Certificates
            data={userCertificates?.data?.data?.data?.certificates}
            t={t}
          />
        )}
      </Box>

      
    </>
  );
}
