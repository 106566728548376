import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

export default function CourseChart({ courseData, t }) {
    const chartRef = useRef(null); // Reference for the canvas

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');
        const data = {
            labels: [t('unlocked'), t('pending')],
            datasets: [{
                data: [courseData?.unlocked ?? 0, courseData?.pending ?? 0],
                backgroundColor: [
                    ' #2eb82e', 
                    '#a6a6a6'   
                ],
                borderColor: '#ffffff',
                borderWidth: 2,
                hoverOffset: 4,
                hoverBorderColor: '#ffffff'
            }]
        };

        const config = {
            type: 'doughnut',
            data: data,
            options: {
                responsive: true, // Make the chart responsive
                maintainAspectRatio: false, // Optional: Allows control over aspect ratio
            plugins: {
                legend: {
                    display: false
                },
            }  
            },
        };

        const myChart = new Chart(ctx, config);

        // Cleanup function to destroy the chart on unmount
        return () => {
            myChart.destroy();
        };
    }, [t]);

    return (

        <div className='d-flex justify-content-center w-100'>
            <div style={{ width: "100%", padding: "25px" }}>
                <canvas ref={chartRef}></canvas>
            </div>
        </div>

    );
}
