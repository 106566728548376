// const BASE_URL = "http://162.19.146.135:1678/";
// const BASE_URL = "http://192.168.9.47:5000/";
// const BASE_URL = "http://208.109.215.160:5000/";
const BASE_URL = "https://admin.spcuniversity.com/node_api/";


const DEFAULT_KEY = 'k2MPvSjDLYupMkF63g1EJRwGJTpp0RVLIF22WN6Em2FwlJe9EaONYKb6dUdkTMevQjPUnmw43RU6cWFCaCPsuP9gH'


// const PAYPAL_CLIENT_ID = "ASvJulHqV3V9Z6jxjQJWpTnb4L2XtqtsMriic0AwU7s6iroMu4BPbv37YV3C6n9wdG1giiZ6VeL8P-er"
const PAYPAL_CLIENT_ID = "AYanNMG1zaLU0Y5oK7kaBmhQsLopp9fvOvWjFE1DatHBidbqaRWPvblrjOHVblmXZFNnRz9OjIhq_7Q2"


export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
