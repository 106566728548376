import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BoardViewTiles from "../../components/board-view/BoardViewTiles";
import BoardViewTable from "../../components/board-view/BoardViewTable";
import { ApiHook } from "../../hooks/apiHook";
import BoardUpgrade from "../../components/board-view/BoardUpgrade";
import { useLocation, useParams } from "react-router";

const BoardView = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { boardId: boardIdPassed, autoBoardId } = location.state || {};

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [boardId, setBoardId] = useState(boardIdPassed);
  const [selectedBoardDetails, setSelectedBoardDetails] = useState({});
  const boardTiles = ApiHook.CallGetBoardTiles();
  const boards = ApiHook.CallGetBoards(boardId, currentPage, itemsPerPage);


  useEffect(() => {

    if(boardTiles.data && !boardIdPassed) {
      setBoardId(boardTiles.data.data.tiles[0].id);
    }

  }, [boardTiles.data]);

  return (
    <>
      <div className="page_head_top">{t("boardView")}</div>
      <BoardViewTiles boardTiles={boardTiles} setBoardId={setBoardId} setSelectedBoardDetails={setSelectedBoardDetails} boardId={boardId}/>
      {boards?.data?.data?.boards?.length > 0 || !boards?.data?.data?.directBoardEntryStatus ? (
        <BoardViewTable
          boards={boards}
          boardId={boardId}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : boards?.data && boards?.data?.data?.directBoardEntryStatus ? (
        <BoardUpgrade boardId={boardId} selectedBoardDetails={selectedBoardDetails}/>
      ) : null}
    </>
  );
};

export default BoardView;
