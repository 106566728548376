import React from 'react'
import { useNavigate } from 'react-router'

export default function LastWatched({ data, t }) {
    const navigate = useNavigate()
    const toLearning = () => {
        navigate('/category')
    }
    const perc = data?.percentage ?? 0
    return (
        <>{
            data?.status ?
                <div class="course-card">
                    
                    <div class="course-title">
                        {/* <span>Tittle</span> */}
                        <h4>{`${data?.category?.name}`}</h4>
                    </div>
                    <div class="progress-container">
                        <span className='course_dtl_left_sc'>
                        <div
                            className="progress-circle"
                            style={{
                                background: `conic-gradient(#6c63ff ${perc}%, #d8d8d8 ${perc}%)`
                            }}
                        >
                            <span>{`${perc}%`}</span>
                        </div>
                        <div class="">
                            <span>{`${data?.taskCompleted} ${t("completed")}`}</span>
                            <span>{`${data?.totalTask} ${t("videos")}`}</span>
                            <span>{`${data?.video?.title}`}</span>
                        </div>
                        </span>

                        <button class="continue-button" onClick={toLearning}>{t(`continue`)}</button>
                    </div>

                </div>
                :
                <div class="course-card">
                    <div class="course-title"><h4>{t(`no_history_found`)}</h4></div>
                    <div class="course-info">
                    </div>
                    <div class="progress-container">
                        <div
                            className="progress-circle"
                            style={{
                                background: `conic-gradient(#6c63ff ${0}%, #d8d8d8 ${0}%)`
                            }}
                        >
                            <span>{`${0}%`}</span>
                        </div>
                        {t(`no_data`)}
                        <button class="continue-button" onClick={toLearning}>{t(`start`)}</button>
                    </div>

                </div>
        }
        </>
    )
}
