import { Icon } from "@iconify/react"
import { useTranslation } from "react-i18next"

export default function AllCourse({ data }) {
    const { t } = useTranslation()
    return (
        <>
            <table style={{ width: "100%" }}>
                <div className="row">

                    {/* <div className="col-md-4"><h5>{t("rank")}</h5></div> */}
                    <div className="col-md-6"><h5>{t("course")}</h5></div>
                    <div className="col-md-6"><h5>{t("status")}</h5></div>
                </div>
                <hr />
                {data.map((e) => {
                    return (
                        <tr className="row">
                            {/* <td className="col-md-4 rank_table_td">
                                <div className="">
                                    <img src={"/images/ranking-icon.png"} alt="" className='w-40' />
                                </div>{e?.Rank?.name ?? "NA"}
                            </td> */}
                            <td className="col-md-6 rank_table_td">
                                <div className="">
                                    {/* <img src={e?.image ?? "/images/crs-ico.png"} alt="" className='w-40' /> */}
                                    <Icon icon="fa-solid:box-open" width="2.5rem" height="2.5rem"  style={{"color": "#3465a4"}}></Icon>
                                </div>{e?.name ?? "NA"}
                            </td>
                            <td className="col-md-6 rank_table_td">
                                {e?.unlocked == 1 ?

                                    <i class="fa-solid fa-circle-check fa-xl" style={{ "color": "#4e9a06" }}></i>
                                    :
                                    <i class="fa-solid fa-lock fa-xl" style={{ "color": "#555753" }}></i>
                                }
                            </td>


                        </tr>
                    )
                })

                }
                {/* <tr>
                    <td>
                        <div className="">
                            <img src={"/images/ranking-icon.png"} alt="" className='w-40' />
                        </div>rank
                    </td>
                    <td>
                        <div className="">
                            <img src={"/images/crs-ico.png"} alt="" className='w-40' />
                        </div>course
                    </td>
                    <td><i class="fa-solid fa-circle-check fa-xl" style={{"color": "#4e9a06"}}></i></td>


                </tr>
                <tr>
                    <td>
                        <div className="">
                            <img src={"/images/ranking-icon.png"} alt="" className='w-40' />

                        </div>rank
                    </td>
                    <td>
                        <div className="">
                            <img src={"/images/crs-ico.png"} alt="" className='w-40' />

                        </div>course
                    </td>
                    <td><i class="fa-solid fa-circle-check fa-xl" style={{"color": "#4e9a06"}}></i></td>

                </tr>
                <tr>
                    <td>
                        <div className="">
                            <img src={"/images/ranking-icon.png"} alt="" className='w-40' />

                        </div>rank
                    </td>
                    <td>
                        <div className="">
                            <img src={"/images/crs-ico.png"} alt="" className='w-40' />

                        </div>course
                    </td>
                    <td><i class="fa-solid fa-lock fa-xl" style={{ "color": "#555753" }}></i></td>


                </tr> */}
            </table>
        </>
    )
}
