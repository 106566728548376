import React, { useState } from "react";
import BoardSingleTile from "./BoardSingleTile";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Skeleton from "react-loading-skeleton";

const BoardViewTiles = ({ boardTiles, setBoardId, setSelectedBoardDetails, boardId }) => {
    // const [activeCard, setActiveCard] = useState(0);

    const activeCard = boardTiles?.data?.data?.tiles?.findIndex(el => el.id == boardId ) || 0;

    const handleCardChange = (index, item) => {
        // setActiveCard(index);
        setSelectedBoardDetails(item);
        setBoardId(item.id);
    };
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div className="owl-carousel owl-theme row">
            {boardTiles.isLoading ? (
                <div>
                    <Skeleton />
                </div>
            ) : (
                <Carousel responsive={responsive}>

                    {boardTiles.data.data.tiles.map((item, index) => (
                        <div key={index}>
                            <BoardSingleTile
                                data={item}
                                activeCard={activeCard}
                                setActiveCard={() => handleCardChange(index, item)}
                                index={index}
                            />
                        </div>
                    ))}
                </Carousel>
            )}
        </div>
    )
};

export default BoardViewTiles;