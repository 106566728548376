import { useEffect, useRef, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DatePickerComponent = ({
    className,
    date,
    setValue,
    clearErrors,
    dateFieldName,
    setSelectedDate,
    disabled,
    past,
    handleChange
}) => {
    const inputRef = useRef(null);
    // states
    const [isCalenderOpen, setIsCalenderOpen] = useState(false);
    // handlefunction
    const handleRefClick = () => {
        openCalender();
    };
    const openCalender = () => {
        setIsCalenderOpen(true);
    };
    const closeCalender = () => {
        setIsCalenderOpen(false);
    };
    const handleCloseDatePicker = () => {
        closeCalender();
    };
    const handleDateChange = (newDate) => {
        if (newDate) {
            setSelectedDate(newDate);
            const formattedDate = newDate.format("YYYY-MM-DD");
            setValue(dateFieldName, formattedDate);
            clearErrors(dateFieldName);
        }
    };

    useEffect(() => {
        if (inputRef.current) {
            const inputElement = inputRef.current;
            inputElement.addEventListener("click", handleRefClick);

            return () => {
                inputElement.removeEventListener("click", handleRefClick);
            };
        }
    }, [openCalender]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} id="parent">
            <DemoContainer id="check" components={["DatePicker"]}>
                <DemoItem>
                    <div id="parent">
                        <DatePicker
                            open={isCalenderOpen}
                            onOpen={openCalender}
                            onClose={handleCloseDatePicker}
                            className={className}
                            value={date}
                            onChange={handleChange ?? ((newDate) => handleDateChange(newDate))}
                            inputRef={inputRef}
                            onAccept={closeCalender}
                            disabled={disabled}
                            disablePast={past}
                        />
                    </div>
                </DemoItem>
            </DemoContainer>
        </LocalizationProvider>
    );
};

export default DatePickerComponent;
