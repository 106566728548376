import React from "react";
import { useTranslation } from "react-i18next";

const BoardSingleTile = (props) => {
    const { t } = useTranslation();

    return (
        <div className="col-sm-auto item pe-0">
            <div
                className={`ewallet_top_qc_balance_box-click ${props.activeCard === props.index ? 'active' : ''}`}
                onClick={() => props.setActiveCard(props.index)}
            >
                <div className="row align-items-center">
                    <div className="ewallet_top_qc_balance_box_cnt-click">
                        <div className="ewallet_top_qc_balance_box_cnt_ico">
                            <img src="/images/network.png" alt="" />
                        </div>
                        <div className="ewallet_top_qc_balance_box_cnt_cnt">
                            <div className="box_amnt_dv board_view_center">
                                <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                                    {props.data.boardType}
                                </div>
                                <div className="ewallt_top_comparison d-block text-center">
                                    <p className="m-0" style={{"display":"inline-block"}}>{t('totalMembers')}</p>
                                    <span className="cmprson_up-click">
                                        {props.data.boardCount}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BoardSingleTile;