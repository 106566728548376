import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import MyPayPalOrderButton from "../payment/PaypalOrderButton";
import { ApiHook } from "../../hooks/apiHook";
import Stripe from "../../views/payment/Stripe";

const BoardUpgrade = ({ boardId, selectedBoardDetails }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState(null);
  const [submitButtonActive, setSubmitButtonActive] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState("");

  const [clientSecret, setClientSecret] = useState("");

  const [formValues, setFormValues] = useState({
    paymentType: null,
    boardId: "",
    totalAmount: 100,
  });

  const [stripePublicKey, setStripePublicKey] = useState("");
  const { data } = ApiHook.CallPaymentMethods("membership_renewal");

  const stripeIntent = ApiHook.CallCreateStripePaymentIntent();
  const boardMutation = ApiHook.CallBoardUpgrade();

  const currency = { symbolLeft: "$" };
  const conversionFactor = 1;

  const CurrencyConverter = (amount, factor) => (amount * factor).toFixed(2);

  const handlePaymentTabClick = (tabId, title) => {
    setActiveTab(tabId);

    if (title === "stripe") {
      setSubmitButtonActive(true);
      setStripePublicKey(
        data?.methods?.find((item) => item.slug === "stripe").publicKey
      );
      if (paymentIntentId === "") {
        const body = {
          boardId,
          type: "manual-board-upgrade",
        };
        stripeIntent.mutateAsync(body, {
          onSuccess: (res) => {
            if (res.status) {
              setClientSecret(res.client_secret);
              setPaymentIntentId(res.id);
            } else {
              toast.error(res.description);
            }
          },
        });
      }
    } else {
      setSubmitButtonActive(true);
    }
  };

  const handleSubmit = (_, stripeData, __, paypalData) => {
    let payload = {
      boardId,
      paypal: paypalData,
      stripe: stripeData,
    };

    boardMutation.mutate(payload, {
      onSuccess: (res) => {
        if (res.status) {

          Promise.allSettled([
            queryClient.invalidateQueries({
              queryKey: ["get-boards", boardId, 1, 10],
            }),
            queryClient.setQueryData(["get-board-tiles"], (oldData) => {
              if (!oldData) return oldData;

              const tiles = oldData.data.tiles;

              const updatedBoardTileIndex = tiles.findIndex(
                (t) => t.id == boardId
              );
              if (updatedBoardTileIndex === -1) return oldData;

              const updatedTile = {
                ...tiles[updatedBoardTileIndex],
                boardCount: tiles[updatedBoardTileIndex].boardCount + 1,
              };

              const updatedTiles = [
                ...tiles.slice(0, updatedBoardTileIndex),
                updatedTile,
                ...tiles.slice(updatedBoardTileIndex + 1),
              ];

              return {
                ...oldData,
                data: {
                  ...oldData.data,
                  tiles: updatedTiles,
                },
              };
            }),
          ]);
        }

        setPaymentIntentId("");
        setClientSecret("");
      },
    });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        backgroundColor: "",
        marginTop: "4rem",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "70%" }}>
        <h3>{t("pay_to_board_directly")}</h3>
        <div className="row row_top justify-content-center form-business">
          <div className="col-lg-12 col-md-12" id="payment">
            <div className="payment_section_tab">
              <div className="regsiter_step_1_view_left_sec_head">
                <strong>
                  {t("entryFee")}: {currency.symbolLeft}{" "}
                  {CurrencyConverter(
                    selectedBoardDetails.entryFee,
                    conversionFactor
                  )}
                </strong>
              </div>
              <div className="row">
                <div className="col-md-3 pe-0">
                  <div
                    className="nav nav-tabs payment_tab_reg"
                    id="nav-tab"
                    role="tablist"
                    style={{
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                    }}
                  >
                    {data?.methods?.map((tab) => (
                      <Fragment key={tab.id}>
                        <button
                          className={`nav-link ${
                            activeTab === tab.id ? "active" : ""
                          }`}
                          id={`nav-${tab.id}-tab`}
                          data-bs-toggle="tab"
                          data-bs-target={`#nav-${tab.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`nav-${tab.id}`}
                          aria-selected={formValues.paymentType === tab.id}
                          onClick={() =>
                            handlePaymentTabClick(tab.id, tab.slug)
                          }
                        >
                          <i className={tab.icon}></i> {tab.code}
                        </button>
                      </Fragment>
                    ))}
                  </div>
                </div>
                <div className="col-md-9 ps-0">
                  <div
                    className="tab-content p-3 border mb-5 payment__tab_cnt"
                    id="nav-tabContent"
                    style={{
                      backgroundColor: "#F6F4FF",
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    {!activeTab && (
                      <p>
                        <strong>
                          {t(
                            "preferredPaymentPlaceholderForManulaBoardUpgrade"
                          )}
                        </strong>
                      </p>
                    )}
                    {data?.methods?.map((tab) => (
                      <div
                        key={tab.id}
                        className={`tab-pane fade ${
                          activeTab === tab.id ? "show active" : ""
                        }`}
                        id={`nav-${tab.id}`}
                        role="tabpanel"
                        aria-labelledby={`nav-${tab.id}-tab`}
                      >
                        {tab.slug === "stripe" && clientSecret !== "" && (
                          <p>
                            <Stripe
                              clientSecret={clientSecret}
                              handleSubmit={handleSubmit}
                              publicKey={stripePublicKey}
                            />
                          </p>
                        )}
                        {tab.slug === "paypal" && (
                          <MyPayPalOrderButton
                            currency={currency?.code}
                            price={selectedBoardDetails.entryFee}
                            handleSubmit={handleSubmit}
                            paymentId={tab.id}
                          />
                        )}
                        {tab.slug === "free-joining" && (
                          <div>place holder text</div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn text-white float-end submit-button rounded-3 bg-color-info"
              disabled={submitButtonActive}
            >
              {t("finish")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardUpgrade;
