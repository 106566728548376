import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../../hooks/apiHook";
import Select from "react-select";
import { PhoneInput } from "react-international-phone";

const SelectAddress = (props) => {
    const { t } = useTranslation();

    const [selectedAddressType, setSelectedAddressType] = useState(props.formValues?.addressType);
    const [country, setSelectedCountry] = useState(
        (props.partyId && props.formValues?.addressData.country)
            ? props.formValues?.addressData?.country
            : props.formValues?.addressData?.countryId || null
    );
    const [selectedState, setSelectedState] = useState(
        (props.partyId && props.formValues?.addressData.state)
            ? props?.formValues?.addressData?.state
            : props?.formValues?.addressData?.stateId || null
    );
    const [phoneNumber, setPhoneNumber] = useState(props?.formValues?.addressData?.phone ?? "");

    const AddressOptions = [
        { value: "hostAddress", label: (t('useTheHostAddress')) },
        { value: "owner", label: t('useTheOwnerAddress') },
        { value: "newAddress", label: t('createANewAddress') }
    ];

    // api
    const countries = ApiHook.CallGetCountries();
    const states = ApiHook.CallGetStates();
    //  const HostDetails = ApiHook.CallGetPartyHosts(0, 1);

    const stateList = () => {
        if (!props?.formValues?.addressData?.countryId) {
            return []; // Return an empty array if there's no country specified.
        }

        const selectedCountry = props.formValues?.addressData?.countryId

        if (!selectedCountry) {
            return []; // Return an empty array if the selected country is not found.
        }

        return states?.data?.filter(state => state.countryId === selectedCountry.value)
    };
    //  handlers
    const handleSelectAddressType = (selected) => {
        setSelectedAddressType(selected);
        props.setValue("addressType", selected);
        props.setValue("addressData", {});
        props.clearErrors("addressType");
    };
    const handlePhoneNumber = (phone) => {
        setPhoneNumber(phone);
        props.setValue("addressData.phone", phone);
        props.clearErrors("addressData.phone");
    };
    const countryChange = (country) => {
        setSelectedCountry(country);
        if (props.partyId) {
            props.setValue("addressData.country", null);
            props.setValue("addressData.state", null);
        }
        props.setValue("addressData.countryId", country);
        props.clearErrors("addressData.countryId");
        props.setValue("addressData.stateId", null);
        setSelectedState(null);
    };
    const changeState = (state) => {
        setSelectedState(state?.value);
        props.setValue("addressData.stateId", state.value);
        props.clearErrors("addressData.stateId");
    };
    return (
        <>
            <div className="row row_top justify-content-center form-business">
                {props?.partyId ? (
                    <div className="regsiter_step_1_view_left_sec_head">{t(props.formValues?.addressType.value)}</div>

                ) : (
                    <div className="regsiter_step_1_view_left_sec_head">{t('selectTheAddress')}</div>
                )}
                <div className="regsiter_second_step_bx">
                    {!props.partyId &&
                        <div className="host-select">
                            <Select
                                isSearchable={false}
                                options={AddressOptions}
                                onChange={handleSelectAddressType}
                                value={selectedAddressType}
                            />
                            <span className="error-message-validator">
                                {t(props.errors?.addressType?.message)}
                            </span>
                        </div>
                    }
                    {selectedAddressType?.value === "newAddress" &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="regsiter_form_box">
                                    <label>{t('address')}<span>*</span></label>
                                    <textarea
                                        {...props.register("addressData.address", { required: "this_field_is_required" })}
                                        onBlur={async () => await props.trigger("addressData.address")}
                                        type="text" className="form-control" placeholder={t("address")}
                                    />
                                    <span className="error-message-validator">
                                        {t(props.errors?.addressData?.address?.message)}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('mobile')}<span>*</span></label>
                                    <PhoneInput
                                        className=""
                                        defaultCountry="us"
                                        value={phoneNumber}
                                        onChange={handlePhoneNumber}
                                    />
                                    <span className="error-message-validator">
                                        {t(props.errors?.addressData?.phone?.message)}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('country')}</label>
                                    <Select
                                        id="addressCountryId"
                                        {...props.register("addressData.countryId", {
                                            required: false
                                        })}
                                        onBlur={async () =>
                                            await props.trigger("addressData.countryId")
                                        }
                                        options={countries?.data}
                                        onChange={countryChange}
                                        defaultValue={props.partyId ? props.formValues?.addressData.country : props.formValues?.addressData.countryId}
                                        value={country}
                                        isSearchable
                                    />
                                    <span className="error-message-validator">
                                        {t(props.errors?.addressData?.countryId?.message)}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('state')}</label>
                                    <Select
                                        isSearchable
                                        id="AddressStateId"
                                        {...props.register("addressData.stateId", {
                                            required: false
                                        })}
                                        options={country ? stateList(states?.data) : []}
                                        value={selectedState ? stateList(countries)?.find(
                                            (state) => state?.value === (selectedState?.value ?? selectedState)
                                        ) : ""}
                                        defaultValue={props.partyId ? props.formValues?.addressData.state : props.formValues?.addressData.stateId}
                                        onChange={changeState}
                                        onBlur={async () => await props.trigger("addressData.stateId")}
                                    />
                                    <span className="error-message-validator">
                                        {props.errors?.addressData?.stateId?.message}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('city')}</label>
                                    <input
                                        {...props.register("addressData.city", { required: false })}
                                        onBlur={async () => await props.trigger("addressData.city")}
                                        type="text" className="form-control" placeholder="City"
                                    />
                                    <span className="error-message-validator">
                                        {props.errors?.addressData?.city?.message}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('email')}<span>*</span></label>
                                    <input
                                        id="addressEmail"
                                        {...props?.register("addressData.email", {
                                            required: t("this_field_is_required"),
                                            pattern: {
                                                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                                message: t(
                                                    "invalid_email_format"
                                                ),
                                            },
                                        })}
                                        onBlur={async () => await props.trigger("addressData.email")}
                                        type="text" className="form-control" placeholder="Email"
                                    />
                                    <span className="error-message-validator">
                                        {props.errors?.addressData?.email?.message}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('zipCode')}<span>*</span></label>
                                    <input
                                        id="addressZip"
                                        {...props.register("addressData.zip", { required: t("this_field_is_required") })}
                                        type="text" className="form-control" placeholder="Zip Code"
                                        onBlur={async () => await props.trigger("addressData.zip")}
                                    />
                                    <span className="error-message-validator">
                                        {props.errors?.addressData?.zip?.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    {/* not editable address (host or owners address) */}
                    {(selectedAddressType?.value === "hostAddress" || selectedAddressType?.value === "owner") && props.partyId &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="regsiter_form_box">
                                    <label>{t('address')}<span>*</span></label>
                                    <textarea
                                        {...props.register("addressData.address", { required: "this_field_is_required" })}
                                        onBlur={async () => await props.trigger("addressData.address")}
                                        type="text" className="form-control" placeholder={t("address")}
                                        disabled
                                    />
                                    <span className="error-message-validator">
                                        {t(props.errors?.addressData?.address?.message)}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('mobile')}<span>*</span></label>
                                    <PhoneInput
                                        className=""
                                        defaultCountry="us"
                                        value={phoneNumber}
                                        onChange={handlePhoneNumber}
                                        disabled
                                    />
                                    <span className="error-message-validator">
                                        {t(props.errors?.addressData?.phone?.message)}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('country')}</label>
                                    <Select
                                        id="addressCountryId"
                                        {...props.register("addressData.country", {
                                            required: false
                                        })}
                                        onBlur={async () =>
                                            await props.trigger("addressData.country")
                                        }
                                        options={countries?.data}
                                        onChange={countryChange}
                                        defaultValue={props.formValues?.addressData.country}
                                        value={props.formValues?.addressData.country}
                                        isDisabled
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('state')}</label>
                                    <Select
                                        isSearchable
                                        id="AddressStateId"
                                        {...props.register("addressData.state", {
                                            required: false
                                        })}
                                        options={country ? stateList(states?.data) : []}
                                        value={props.formValues?.addressData.state}
                                        onChange={changeState}
                                        onBlur={async () => await props.trigger("addressData.stateId")}
                                        isDisabled
                                    />
                                    <span className="error-message-validator">
                                        {props.errors?.addressData?.stateId?.message}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('city')}</label>
                                    <input
                                        {...props.register("addressData.city", { required: false })}
                                        onBlur={async () => await props.trigger("addressData.city")}
                                        type="text" className="form-control" placeholder="City"
                                        disabled
                                    />
                                    <span className="error-message-validator">
                                        {props.errors?.addressData?.city?.message}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('email')}<span>*</span></label>
                                    <input
                                        id="addressEmail"
                                        {...props?.register("addressData.email", {
                                            required: t("this_field_is_required"),
                                            pattern: {
                                                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                                message: t(
                                                    "invalid_email_format"
                                                ),
                                            },
                                        })}
                                        onBlur={async () => await props.trigger("addressData.email")}
                                        type="text" className="form-control" placeholder="Email"
                                        disabled
                                    />
                                    <span className="error-message-validator">
                                        {props.errors?.addressData?.email?.message}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="regsiter_form_box">
                                    <label>{t('zipCode')}<span>*</span></label>
                                    <input
                                        id="addressZip"
                                        {...props.register("addressData.zip", { required: t("this_field_is_required") })}
                                        type="text" className="form-control" placeholder="Zip Code"
                                        onBlur={async () => await props.trigger("addressData.zip")}
                                        disabled
                                    />
                                    <span className="error-message-validator">
                                        {props.errors?.addressData?.zip?.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
};

export default SelectAddress;