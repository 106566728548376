import React from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "../Common/buttons/SubmitButton";
import TablePagination from "../Common/table/TablePagination";
import { Link } from "react-router-dom";

const BoardViewTable = ({ boards, boardId, itemsPerPage, setItemsPerPage, currentPage, setCurrentPage }) => {
    const { t } = useTranslation();
    const startPage = 1;

    // handle Pagination
    const handleItemsPerPageChange = (event) => {
        const selectedValue = parseInt(event.target.value);
        setItemsPerPage(selectedValue);
        setCurrentPage(1);
    }
    const toNextPage = () => {
        if (currentPage < boards.data.data.totalPages) {
            setCurrentPage(boards.data.data.currentPage + 1);
        }
    }
    const toLastPage = () => {
        setCurrentPage(boards.data.data.totalPages);
    };
    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
        }
    };
    const toFirstPage = () => {
        setCurrentPage(startPage);
    };
    return (
        <div className="ewallet_table_section">
            <div className="ewallet_table_section_cnt">
                    <div className="table-responsive min-hieght-table">
                    <table className="striped table-route table-route-table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t('dateOfJoining')}</th>
                                <th>{t('boardUsername')}</th>
                                <th>{t('boardSplit')}</th>
                                <th>{t('boardView')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(boards.isLoading || boards.data.data.boards.length === 0) ? (
                                <tr>
                                    <td colSpan="6">
                                        <div className="nodata-table-view">
                                            <div className="nodata-table-view-box">
                                                <div className="nodata-table-view-box-img">
                                                    <img src="/images/no-data-image1.jpg" alt="" />
                                                </div>
                                                <div className="nodata-table-view-box-txt">
                                                    {t("sorry_no_data_found")}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                <>
                                    {boards.data.data.boards.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.dateOfJoining}</td>

                                            <td>{item.name}</td>
                                            <td>{item.isSplit ? t("yes") : t("no")}</td>
                                            <td>
                                                <Link to={`/board-tree/${boardId}/${item.id}`}>
                                                    <img className="img-sm" src="/images/flow-diagram.png" alt="" />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}

                                </>
                            )}
                        </tbody>
                    </table>
                    {boards.data && boards.data.data.boards.length > 0 &&
                        <TablePagination
                            startPage={1}
                            currentPage={boards.data.data.currentPage}
                            itemsPerPage={itemsPerPage}
                            totalPages={boards.data.data.totalPages}
                            handleItemsPerPageChange={handleItemsPerPageChange}
                            toNextPage={toNextPage}
                            toLastPage={toLastPage}
                            toPreviousPage={toPreviousPage}
                            toFirstPage={toFirstPage}
                        />
                    }
                </div>
            </div>
        </div>

    )
};

export default BoardViewTable;